import { AnyAction, Dispatch } from 'redux';
import { services } from "redux/services";
import { ServiceState } from 'utils/types';
import {
    GET_MASTER_LOOKUP_DATA_START,
    GET_MASTER_LOOKUP_DATA_SUCCESS,
    GET_MASTER_LOOKUP_DATA_FAIL,
} from "../actionTypes";

// getMasterLookupData is only for lookup APIs
export function getMasterLookupData(serviceType: keyof typeof services):any {
    return (dispatch: Dispatch<AnyAction>) => {
        let actionService:any = services as unknown as ServiceState;

        if (actionService[serviceType]) {
            dispatch({ type: GET_MASTER_LOOKUP_DATA_START, serviceType });
            actionService[serviceType]({})
                .then((res:any) => {
                    const { result } = res;
                    dispatch({ type: GET_MASTER_LOOKUP_DATA_SUCCESS, payload: result, serviceType });
                })
                .catch(() => {
                    dispatch({ type: GET_MASTER_LOOKUP_DATA_FAIL, serviceType });
                });
        }
    };
}